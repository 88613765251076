<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hide-footer
    size="sm"
    centered
    :title="$t('Введите смс код')"
    @close="close"
    @cancel="close"
    @ok.prevent="save"
  >

    <div class="">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="save"
      >
        <b-form-group
          label-for="code"
        >
          <b-form-input
            id="code"
            v-model="sms_code"
            v-mask="'####'"
            name="code"
            type="number"
            placeholder="0000"
          />
        </b-form-group>
        <div
          class="text-right mt-3"
        >
          <b-button
            variant="primary"
            class="btn-tour-skip"
            @click="save"
          >
            <span class="mr-25 align-middle">Tekshirish</span>
          </b-button>
        </div>

      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { showToast } from '@/utils/toast'

export default {
  name: 'SmsCode',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sms_code: null,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    save() {
      if (this.sms_code) {
        this.$emit('save', this.sms_code)
      } else {
        showToast('danger', this.$t('Kodni kiriting'), 'XIcon')
      }
    },
    close() {
      this.visible = false
      this.$emit('close', true)
    },
  },
}
</script>

<style scoped>

</style>
