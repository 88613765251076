<template>
  <div>
    <div class="">
      <!-- fiter -->
      <el-form label-position="top" label-width="100px" :model="filterModel">
        <table class="table-responsive">
          <tr>
            <td>
              <el-form-item class="mr-1" label="Дата начала">
                <x-date-picker
                  v-model="filterModel.from_date"
                  :clearable="false"
                  :placeholder="$t('Дата начала')"
                  @input="getItems"
                />
              </el-form-item>
            </td>
            <td>
              <el-form-item class="mr-1" label="Дата окончания">
                <x-date-picker
                  v-model="filterModel.to_date"
                  :clearable="false"
                  :placeholder="$t('Дата окончания')"
                  @input="getItems"
                />
              </el-form-item>
            </td>
            <td>
              <el-form-item class="mr-1" label=" ">
                <b-button
                  variant="outline-primary"
                  class="btn-tour-skip mr-1"
                  @click="clearFilter"
                >
                  <feather-icon icon="XIcon" size="16" />
                </b-button>
              </el-form-item>
            </td>
            <td class="w-100">
              <b-button
                variant="outline-success"
                class="float-right"
                size="small"
                :disabled="xlLoading"
                @click="tableToExcel"
              >
                <img src="/images/excel_1.svg" alt="" />
                Yuklab olish
              </b-button>
            </td>
          </tr>
        </table>
      </el-form>
      <!--  -->
      <!-- <b-button
        variant="outline-success"
        class="float-right"
        size="small"
        :disabled="xlLoading"
        @click="tableToExcel"
      >
        <img src="/images/excel_1.svg" alt="" />
        Yuklab olish
      </b-button> -->
    </div>
    <b-tabs>
      <!-- <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab.id)"
      > -->
      <b-overlay :show="loading">
        <good-table-selectable
          v-if="items"
          ref="table"
          :items="items.data"
          :columns="columns"
          :total="items.total"
          :page="page"
          :has-show="true"
          :status="2"
          model="payment"
          :filter="filterModel"
          :has-search="false"
          @getItems="getItems"
          @selectedRows="selectedRows"
          @editt="openEditPayment"
          @onPageChange="(p) => (page = p)"
        />
      </b-overlay>
      <!-- </b-tab> -->
    </b-tabs>
    <!-- Table for Excel -->
    <div v-if="xlContracts && xlContracts.data">
      <good-table-selectable
        v-show="false"
        ref="tablee"
        class="tablee"
        :items="xlContracts.data"
        :columns="columns"
        :total="xlContracts.total"
        :page="page"
        :status="filterModel.status"
        model="payment"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      />
    </div>
    <!--  -->
    <!-- <b-modal
      v-if="paymentInfo"
      v-model="openEdit"
      cancel-variant="outline-secondary"
      :ok-title="$t('Сохранить')"
      no-close-on-backdrop
      :cancel-title="$t('Отмена')"
      centered
      title="Summani tahrirlash"
      @ok.prevent="save"
    >
      <b-form class="auth-login-form mt-2 row">
        <b-form-group
          :label="$t('Значение')"
          label-for="amount"
          class="col-12"
        >
          <cleave
            id="amount"
            v-model="paymentInfo.amount"
            class="form-control"
            :options="cleaveOption"
            placeholder="0"
          />
        </b-form-group>
        <b-form-group
          v-if="openSmsModal"
          :label="$t('Код')"
          class="col-12"
          label-for="code"
        >
          <b-form-input
            id="code"
            v-model="paymentInfo.sms_code"
            v-mask="'####'"
            name="code"
            type="number"
            placeholder="0000"
          />
        </b-form-group>
      </b-form>
    </b-modal> -->
    <!-- <ConfirmCode
      v-if="openSmsModal"
      :visible="openSmsModal"
      @close="openSmsModal=false"
      @save="confirmForException"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/utils/toast";
import GoodTableSelectable from "@/views/table/vue-good-table/GoodTableContractCashTable.vue";
import Cleave from "vue-cleave-component";
import ConfirmCode from "@/views/order/components/confirm_code.vue";

export default {
  name: "Index",
  components: {
    GoodTableSelectable,
    Cleave,
    ConfirmCode,
  },
  data() {
    return {
      page: 1,
      loading: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: "thousand" },
      filterModel: {
        per_page: 50,
        number: null,
        search: null,
        from_date: null,
        to_date: null,
        status: 0,
        relations: "contract|contract.client|oldContract",
      },
      openEdit: false,
      // paymentInfo: null,
      openSmsModal: false,
      items: null,
      xlLoading: false,
      xlContracts: null,
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      },
      settingTabs: [
        {
          id: 0,
          name: this.$t("Для подтверждения"),
        },
        {
          id: 1,
          name: this.$t("Одобренные"),
        },
        {
          id: 2,
          name: this.$t("Подтверждено директором"),
        },
      ],
      payment_ids: [],
      columnsTable: [],
      columnsArr: [
        {
          label: "#",
          field: "row_number",
        },
        {
          label: this.$t("Фамилия"),
          field: this.lastnameField,
          filterField: "lastname",
          // field: 'contract.client.lastname',
          showField: true,
        },
        {
          label: this.$t("Имя"),
          field: "contract.client.firstname",
          showField: true,
        },
        {
          label: this.$t("Отчества"),
          field: "contract.client.middlename",
          showField: true,
        },
        {
          label: this.$t("Телефон"),
          field: "contract.client.phone",
        },

        {
          label: this.$t("Сумма"),
          field: "amount",
          isAmount: true,
        },

        {
          label: this.$t("Дата оплаты"),
          isDateTime: true,
          field: "complete_received_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      payments: "contract/GET_PAYMENT_ITEMS",
    }),
    columns() {
      return this.columnsTable;
    },
    title() {
      return this.settingTabs[this.filterModel.status].name;
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    "filterModel.per_page": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
  },
  mounted() {
    this.columnsTable = this.columnsArr;

    this.getItems();
  },
  methods: {
    ...mapActions({
      getItemsAction: "resource/getClickTransactions",
    }),
    clearFilter() {
      this.filterModel.from_date = null;
      this.filterModel.to_date = null;
      this.getItems();
    },

    // onChange(id) {
    //   this.filterModel.status = id
    //   this.getItems()
    //   const cols = [...this.columnsArr]
    //   if (id == 1) {
    //     cols.splice(cols.length - 1, 0, {
    //       label: this.$t('Кто одобрил'),
    //       field: 'confirmed_user1.name',
    //     }, {
    //       label: this.$t('Дата одобрено'),
    //       isDate: true,
    //       field: 'confirmed_at_1',
    //     })
    //   } else if (id == 2) {
    //     cols.splice(cols.length - 1, 0, {
    //       label: this.$t('Кто одобрил'),
    //       field: 'confirmed_user1.name',
    //     }, {
    //       label: this.$t('Дата одобрено'),
    //       isDate: true,
    //       field: 'confirmed_at_1',
    //     }, {
    //       label: this.$t('Кто подтвердил'),
    //       field: 'confirmed_user2.name',
    //     }, {
    //       label: this.$t('Подтвержденная дата'),
    //       isDate: true,
    //       field: 'confirmed_at_2',
    //     })
    //   }
    //   this.columnsTable = cols
    // },

    searchFilter(search) {
      this.filterModel.search = search;
      this.getItems();
    },
    lastnameField(row) {
      if (row.contract && row.contract.client && row.contract.client.lastname) {
        return row.contract.client.lastname;
      }
      if (row.old_contract) {
        return [
          row.old_contract.lastname,
          row.old_contract.firstname,
          row.old_contract.middlename,
        ].join(" ");
      }
      return null;
    },
    phoneField(row) {
      if (row.contract && row.contract.client && row.contract.client.phone) {
        return row.contract.client.phone;
      }
      if (row.old_contract) {
        return row.old_contract.phone;
      }
      return null;
    },
    middlenameField(row) {
      if (
        row.contract &&
        row.contract.client &&
        row.contract.client.middlename
      ) {
        return row.contract.client.middlename;
      }
      if (row.old_contract) {
        return row.old_contract.middlename;
      }
      return null;
    },
    async getItems() {
      this.loading = true;
      await this.getItemsAction({
        ...this.filterModel,
        page: this.page,
        // relations:
        //   'contract|oldContract',
      })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getXlItems() {
      this.xlLoading = true;
      const filter = {
        ...this.filterModel,
        per_page: 9999,
        // relations:
        //   'contract|oldContract',
      };
      filter.per_page = 99999;
      await this.getItemsAction(filter)
        .then((res) => {
          this.xlContracts = res.data;
        })
        .finally(() => {
          this.xlLoading = false;
        });
    },
    async tableToExcel(table) {
      await this.getXlItems();
      // await this.getItems()
      const tableeee = document.querySelector(
        "div.tablee div.vgt-responsive table"
      );
      const secondRow = tableeee.querySelector("thead tr:nth-child(2)");
      if (secondRow) {
        secondRow.remove();
      }
      const rows = tableeee.querySelectorAll("table tr");
      rows.forEach((row) => {
        const lastCell = row.firstElementChild;
        if (lastCell.querySelector("th.vgt-checkbox-col")) {
          lastCell.remove();
        }
      });
      setTimeout(() => {
        if (!table.nodeType) table = tableeee;
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML };
        const link = document.createElement("a");
        link.download = `${this.title}.xls`;
        link.href = this.uri + this.base64(this.format(this.template, ctx));
        link.click();
      }, 10);
    },
  },
};
</script>

<style scoped></style>
